// ------------------------------------------------------------------------
// -- OBJECTS - GRID
// ------------------------------------------------------------------------

.grid {
    margin-bottom: 1.5rem;

    &--large {
        margin-bottom: 3rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: $extra-large) {
        &--disabled {
            .grid__row {
                display: block;
                margin: 0 (-($grid-gutter * 1rem));
            }
        }

        &--justifybetween {
            .grid__row {
                justify-content: space-between;
            }
        }

        &--aligncenter {
            .grid__row {
                align-items: center;
            }
        }

        &--alignstart {
            .grid__row {
                align-items: flex-start;
            }
        }

        &--alignend {
            .grid__row {
                align-items: flex-end;
            }
        }
    }
}

[class*="grid__col"] {
    margin: ($grid-gutter * 2rem) 0;
    //overflow: hidden;

    .grid--large & {
        margin: ($grid-gutter-large * 2rem) 0;
    }
}

.grid__row {
    margin: 0;
    padding: 0;
    list-style: none;
}

@media (min-width: $extra-large) {
    .grid__row {
        display: flex;
        flex-wrap: wrap;
        margin: -($grid-gutter * 1rem);

        .grid--large & {
            margin: -($grid-gutter-large * 1rem);
        }

        .grid__col--nogrow {
            flex: 0 0 auto;
        }

        .grid__col--alignstart {
            align-self: flex-start;
        }
    }

    [class*="grid__col"] {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        margin: ($grid-gutter * 1rem);

        .grid--large & {
            margin: ($grid-gutter-large * 1rem);
        }
    }

    [class*="--small2"],
    [class*="--small3"] {
        flex-basis: calc((100% / 2) - (#{$grid-gutter} * 2rem) - .01px);

        .grid--large & {
            flex-basis: calc((100% / 2) - (#{$grid-gutter-large} * 2rem) - .01px);
        }
    }
}

@media (min-width: $mega-large) {
    [class*="grid__col"] {
        flex: 1 1 0%;
    }

    .grid__col4,
    .grid__col4--small2,
    .grid__col4--small3 {
        flex-grow: 0;
        flex-basis: calc((100% / 12 * 4) - (#{$grid-gutter} * 2rem) - .01px);

        .grid--large & {
            flex-basis: calc((100% / 12 * 4) - (#{$grid-gutter-large} * 2rem) - .01px);
        }
    }

    .grid__col6,
    .grid__col6--small2,
    .grid__col6--small3 {
        flex-grow: 0;
        flex-basis: calc((100% / 12 * 6) - (#{$grid-gutter} * 2rem) - .01px);

        .grid--large & {
            flex-basis: calc((100% / 12 * 6) - (#{$grid-gutter-large} * 2rem) - .01px);
        }
    }

    .grid__col3,
    .grid__col3--small2 {
        flex-grow: 0;
        flex-basis: calc((100% / 12 * 3) - (#{$grid-gutter} * 2rem) - .01px);

        .grid--large & {
            flex-basis: calc((100% / 12 * 3) - (#{$grid-gutter-large} * 2rem) - .01px);
        }
    }

    .grid__col2,
    .grid__col2--small2 {
        flex-grow: 0;
        flex-basis: calc((100% / 12 * 2) - (#{$grid-gutter} * 2rem) - .01px);

        .grid--large & {
            flex-basis: calc((100% / 12 * 2) - (#{$grid-gutter-large} * 2rem) - .01px);
        }
    }

    .grid__col1 {
        flex-grow: 0;
        flex-basis: calc((100% / 12 * 1) - (#{$grid-gutter} * 2rem) - .01px);

        .grid--large & {
            flex-basis: calc((100% / 12 * 1) - (#{$grid-gutter-large} * 2rem) - .01px);
        }
    }
}
