// ------------------------------------------------------------------------
// -- COMPONENTS - MAP
// ------------------------------------------------------------------------

.map {
    background-color: $gray-100;
    border-radius: 1rem;
    overflow: hidden;

    @media (min-width: $medium) {
        display: flex;
    }
}

.map__address {
    padding: 3rem;

    .btn  {
        margin-top: 2rem;
    }

    @media (min-width: $medium) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 0;
    }
}

.map__name {
    margin-bottom: 1rem;

    strong {
        display: block;
        margin-bottom: 1rem;
        color: $color-secondary;
    }
}

.map__iframe {
    iframe {
        display: block;
        width: 100%;
        height: 25rem;
        margin: 0;
        padding: 0;
    }

    @media (min-width: $medium) {
        flex: 1 1 0%;

        iframe {
            height: 35rem;
        }
    }
}