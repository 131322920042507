// ------------------------------------------------------------------------
// -- COMPONENTS - CARDS
// ------------------------------------------------------------------------

.card {
    overflow: hidden;
}

.card__inner {
    @media (min-width: $medium) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1rem;
        margin-right: -1rem;
    }

    @media (min-width: $large) {
        margin-left: -2rem;
        margin-right: -2rem;
    }
}

.card__item {
    text-align: center;
    margin: 1rem;

    @media (min-width: $medium) {
        flex: 1 1 calc(50% - 2rem);
    }

    @media (min-width: $large) {
        margin: 2rem;
        flex-basis: 0%;
    }
}

.card__icon {
    position: relative;
    z-index: 1;
    display: inline-flex;
    width: 12rem;
    height: 12rem;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 50%;
    background-color: $color-secondary;
    font-size: 4.8rem;
}

.card__content {
    color: $white;
    background-color: $color-secondary;
    padding: 7rem 3rem 3rem;
    margin-top: -7rem;
    border-radius: .75rem;
}

.card__ttl {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 2.4rem;

    &::after {
        content: "";
        background-color: $white;
        height: .2rem;
        width: 10rem;
        display: block;
        margin: 1rem auto 2rem;
    }
}

.card__btn {
    display: inline-flex;
    margin-top: 1rem;
    align-items: center;
    background-color: $color-primary;
    color: $white;
    padding: .25rem 1.5rem;
    border-radius: .5rem;
    transition: background-color .3s ease;

    .icon {
        margin-left: 1rem;
    }

    &:focus,
    &:hover,
    &:active {
        background-color: $color-secondary-light;
        color: $white;
    }
}