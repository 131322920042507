// ------------------------------------------------------------------------
// -- UTILITIES - A11Y
// ------------------------------------------------------------------------

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(100%);
    white-space: nowrap;
}

.skiplinks {
    display: none;
    @media (min-width: $small) {
        display: block;
        position: absolute;
    }

    a {
        position: absolute;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        font-size: 1.4rem;
        padding: .5em;
        line-height: 1;
        color: $white;
        background: $color-secondary;
        text-decoration: none;

        &:focus {
            position: static;
            overflow: visible;
            clip: auto;
        }
    }
}

// -- CONTRAST HIGH
// ----------------------------------------------

//.contrast--high { }



// -- CONTRAST INV
// ----------------------------------------------

//.contrast--inv { }



// -- LINE HEIGHT
// ----------------------------------------------

.linespace--high * {
    line-height: 200%;

    h1,
    .h1-like,
    h2,
    .h2-like,
    h3,
    .h3-like,
    h4,
    .h4-like,
    h5,
    .h5-like,
    h6,
    .h6-like {
        line-height: 150%;
    }
}



// -- JUSTIFICATION
// ----------------------------------------------

.justification--left * {
    text-align: left;
}
