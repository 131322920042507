// ------------------------------------------------------------------------
// -- COMPONENTS - BANNER
// ------------------------------------------------------------------------

.banner {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: $medium) {
        height: 375px;

        &::before {
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            content: "";
            height: 7rem;
            background-color: $white;
        }

        &::before {
            top: 0;
            background: linear-gradient(180deg, rgba($white, 1) 0, rgba($white, 0) 100%);
        }
    }

    &--isintro {
        @media (min-width: $medium) {
            height: 790px;

            &::after {
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                content: "";
                height: 7rem;
                background-color: $white;
            }

            &::after {
                bottom: 0;
                background: linear-gradient(0deg, rgba($white, 1) 0, rgba($white, 0) 100%);
            }
        }
    }

    &--contact {
        background-image: url(../img/bg-contact.jpg);
    }

    &--home {
        background-image: url(../img/bg-index.jpg);
    }

    &--mentions {
        background-image: url(../img/bg-mentions.jpg);
    }

    &--metier {
        background-image: url(../img/bg-metier.jpg);
    }

    &--marches {
        background-image: url(../img/bg-marches.jpg);
    }

    &--services {
        background-image: url(../img/bg-services.jpg);
    }
}

.banner__overlay {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);

    .banner--isintro &{
        background-color: rgba($black, .35);
    }

    .banner--marches & {
        background-color: rgba($black, .05);
    }

    .banner--mentions & {
        background-color: rgba($black, .1);
    }

    .banner--services & {
        background-color: rgba($black, .1);
    }

    .banner--contact & {
        background-color: rgba($black, .1);
    }
}

.banner__inner {
    position: relative;
    @media (min-width: $medium) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100%;
    }
}

.banner__content {
    color: $white;
    padding: 3rem 1.5rem;

    @media (min-width: $medium) {
        text-align: center;
        padding: 10rem;

        .banner--isintro & {
            padding: 0;
            width: 60%;
            margin-left: auto;
            text-align: right;
        }
    }
}

.banner__ttl {
    text-shadow: .1rem .2rem rgba($black, .85);
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    line-height: 1.2;

    @media (min-width: $medium) {
        font-size: 2.4rem;
    }

    @media (min-width: $large) {
        font-size: 3.8rem;
    }
}

.banner__slogan {
    margin: 0;
    text-shadow: .1rem .2rem rgba($black, .85);
    font-style: italic;
    font-size: 2rem;
    @media (min-width: $medium) {
        font-size: 2.6rem;
    }
}

.banner__txt {
    text-shadow: .1rem .2rem rgba($black, .85);

    @media (min-width: $medium) {
        font-size: 2.4rem;
    }

    .banner--isintro & {
        margin-bottom: 3rem;

        @media (min-width: $medium) {
            font-size: 1.8rem;
        }
    }
}

.banner__btn {
    width: 100%;

    & + .banner__btn {
        margin-top: 1rem;
    }

    @media (min-width: $medium) {
        width: auto;

        & + .banner__btn {
            margin-top: 0;
            margin-left: 1rem;
        }
    }
}