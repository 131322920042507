// ------------------------------------------------------------------------
// -- TOOLS - BREAKPOINTS
// ------------------------------------------------------------------------

$bp-aliases: (
    'tiny'          : (max-width: #{$tiny - 1}),
    'small'         : (max-width: #{$small - 1}),
    'medium'        : (max-width: #{$medium - 1}),
    'large'         : (max-width: #{$large - 1}),
    'extra-large'   : (max-width: #{$extra-large - 1}),
    'tiny-up'       : (min-width: #{$tiny}),
    'small-up'      : (min-width: #{$small}),
    'medium-up'     : (min-width: #{$medium}),
    'large-up'      : (min-width: #{$large}),
    'extra-large-up': (min-width: #{$extra-large}),
    'retina'        : (min-resolution: 2dppx)
);

@mixin respond-to($name) {
    @if map-has-key($bp-aliases, $name) {
        @media #{inspect(map-get($bp-aliases, $name))} {
            @content;
        }
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$breakpoints` map.';
    }
}
