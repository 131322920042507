// ------------------------------------------------------------------------
// -- SETTINGS - TYPOGRAPHY
// ------------------------------------------------------------------------

// -- FONT IMPORT
// ----------------------------------------------

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.woff2') format('woff2'),
         url('../fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
         url('../fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


// -- FONT STACKS
// ----------------------------------------------

$font-family-base      : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
$font-family-headings  : "Poppins", sans-serif !default;
$font-family-icons     : "icomoon" !default;


// -- FONT SIZES
// ----------------------------------------------

$font-size-base : 1.8rem !default;
$font-sizes: (
    base: (
        mobile  : 1.6rem,
        desktop : $font-size-base
    ),
    h1: (
        mobile  : 2.4rem,
        desktop : 2.6rem
    ),
    h2: (
        mobile  : 2.2rem,
        desktop : 2.4rem
    ),
    h3: (
        mobile  : 2rem,
        desktop : 2.2rem
    ),
    h4: (
        mobile  : 1.8rem,
        desktop : 2rem
    ),
    h5: (
        mobile  : 1.6rem,
        desktop : 1.8rem
    ),
    h6: (
        mobile  : 1.4rem,
        desktop : 1.6rem
    )
) !default;



// -- FONT WEIGHT
// ----------------------------------------------

$font-weight-base      : 400 !default;
$font-weight-headings  : 700 !default;


// -- LINE HEIGHT
// ----------------------------------------------

$line-height-base  : 1.6 !default;

$line-heights: (
    base: (
        mobile  : 1.4,
        desktop : $line-height-base
    ),
    title: (
        mobile  : 1.4,
        desktop : 1.4
    )
) !default;