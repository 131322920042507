// ------------------------------------------------------------------------
// -- COMPONENTS - SPECS
// ------------------------------------------------------------------------

.spec {
    overflow: hidden;
}

.spec__inner {
    @media (min-width: $small) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
    }
}

.spec__item {
    background-color: $gray-100;
    padding: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;

    @media (min-width: $small) {
        margin: 1rem;
        flex: 1 1 calc(50% - 2rem);
    }

    @media (min-width: $large) {
        display: flex;
    }
}

.spec__img {
    margin-bottom: 1rem;
    height: 10rem;
    overflow: hidden;

    @media (min-width: $large) {
        flex-shrink: 0;
        width: 15rem;
        height: auto;
        margin-right: 1.5rem;
        margin-bottom: 0;
    }
}

.spec__ttl {
    font-size: 2rem;
    margin-bottom: 0;

    @media (min-width: $large) {
        margin-bottom: .5rem;
    }
}