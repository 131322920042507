// ------------------------------------------------------------------------
// -- COMPONENTS - NAV
// ------------------------------------------------------------------------

.nav {
    background-color: $color-secondary;

    &--hidden {
        display: none;

        @media (min-width: $medium) {
            display: block;
        }
    }

    @media (min-width: $medium) {
        flex-grow: 1;
        background: none;
    }
}

.nav__list {
    padding: 0;
    margin: 0;
    list-style: none;

    @media (min-width: $medium) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

.nav__item {
    border-bottom: .1rem solid darken($color-secondary, 15);

    @media (min-width: $medium) {
        display: flex;
        align-items: center;
        border: 0;

        & + .nav__item::before {
            display: inline-block;
            width: .5rem;
            height: .5rem;
            border-radius: 50%;
            background-color: $color-primary;
            content: "";
            margin: 0 .25rem;
        }
    }

    @media (min-width: $large) {
        & + .nav__item::before {
            margin: 0 1rem;
        }
    }
}

.nav__link {
    display: block;
    text-align: center;
    padding: 1rem;
    color: $white;
    background-color: $color-secondary;
    font-family: $font-family-headings;
    transition: color .3s ease;
    white-space: nowrap;
    font-size: 1.4rem;

    &:focus,
    &:hover,
    &:active {
        background-color: darken($color-secondary, 5);
        color: $white;
    }

    @media (min-width: $medium) {
        background: none;
        color: $color-secondary;
        padding: .5rem 1rem;

        &:focus,
        &:hover,
        &:active,
        &[aria-current] {
            background: none;
            color: $color-primary;
        }
    }

    @media (min-width: $large) {
        font-size: 1.8rem;
    }
}