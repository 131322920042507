// ------------------------------------------------------------------------
// -- COMPONENTS - HEADINGS
// ------------------------------------------------------------------------

.ttl {
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    color: $color-secondary;
    hyphens: none;
    font-size: 3.2rem;

    &::after {
        content: "";
        display: block;
        margin: .25em auto 1em;
        height: .5rem;
        width: 12rem;
        background-color: $color-secondary;
    }
}

.ssttl {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 2rem;
    hyphens: none;

    @media (min-width: $medium) {
        font-size: 3.4rem;
    }
}

.ssttl2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    hyphens: none;
    color: $color-secondary;

    @media (min-width: $medium) {
        font-size: 2.4rem;
    }
}