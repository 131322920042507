// ------------------------------------------------------------------------
// -- OBJECTS - MEDIA OBJECT
// ------------------------------------------------------------------------

.media {
    & + .media {
        margin-top: 2rem;
    }

    @media (min-width: $small) {
        &--left,
        &--right {
            display: flex;
            align-items: center;
        }
        &--right {
            flex-direction: row-reverse;
        }
    }
}

.media__img {
    margin-bottom: 1.5rem;
    @media (min-width: $small) {
        .media--left &,
        .media--right & {
            flex: 1 1 0%;
            margin-bottom: 0;
        }
        .media--left & {
            margin-right: 1.5rem;
        }
        .media--right & {
            margin-left: 1.5rem;
        }
    }
}

.media__body {
    @media (min-width: $small) {
        .media--left &,
        .media--right & {
            flex: 1 1 0%;
        }
    }
}