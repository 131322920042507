// ------------------------------------------------------------------------
// -- COMPONENTS - CIRCLE
// ------------------------------------------------------------------------

.circle {
    overflow: hidden;
}

.circle__inner {
    @media (min-width: $small) {
        display: flex;
        margin: 0 -1rem;
    }
}

.circle__item {
    text-align: center;
    padding: 3rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;

    br {
        display: none;

        @media (min-width: $large) {
            display: block;
        }
    }

    @media (min-width: $small) {
        flex: 1 1 0%;
        margin: 1rem;
    }
}

.circle__img {
    margin: 0 auto 1rem;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    overflow: hidden;

    @media (min-width: $large) {
        width: 25rem;
        height: 25rem;
        margin-bottom: 1.5rem;
    }
}

.circle__ttl {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;

    @media (min-width: $large) {
        font-size: 2.6rem;
        margin-bottom: .5rem;
    }
}