// ------------------------------------------------------------------------
// -- COMPONENTS - FOOTER
// ------------------------------------------------------------------------

.footer {
    background-color: $color-secondary;
    color: $white;
    text-align: center;
}

.footer__inner {
    padding: 1.5rem;

    @media (min-width: $large) {
        padding: 5rem;
        display: flex;
        justify-content: center;
    }
}

.footer__logo {
    display: inline-block;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 2rem;
    background-color: $white;

    @media (min-width: $large) {
        align-self: flex-start;
        margin: -5rem 5rem 1.5rem;
        border-radius: 0 0 2rem 2rem;
    }
}

.footer__adress {
    @media (min-width: $large) {
        flex-basis: 33.333%;

        &--left {
            text-align: right;
        }

        &--right {
            text-align: left;
        }
    }
}

.footer__bottom {
    background-color: $color-secondary-light;
    padding: 1rem;
    color: $white;
    font-size: 1.4rem;

    [href] {
        color: $white;
        transition: color .3s ease;

        &:focus,
        &:hover,
        &:active {
            color: $color-secondary;
        }
    }

    @media (min-width: $large) {
        font-size: 1.6rem;
        padding: 1.5rem;
        display: flex;
        justify-content: center;
    }
}

.footer__link {
    @media (min-width: $large) {
        & + .footer__link {
            &::before {
                content: " - ";
                margin: 0 .5rem;
            }
        }
    }
}
