// ------------------------------------------------------------------------
// -- TOOLS - TYPOGRAPHY
// ------------------------------------------------------------------------

// -- FONT SIZE
// --------------------------------------------------

@mixin font-size($elem) {
    $q: map-get($font-sizes, $elem);
    $mob: map-get($q, 'mobile');
    $desk: map-get($q, 'desktop');
    font-size: $mob;

    @include respond-to('small-up') {
        font-size: $desk;
    }
}


// -- LINE HEIGHT
// --------------------------------------------------

@mixin line-height($elem) {
    $q: map-get($line-heights, $elem);
    $mob: map-get($q, 'mobile');
    $desk: map-get($q, 'desktop');
    line-height: $mob;

    @include respond-to('small-up') {
        line-height: $desk;
    }
}
