// ------------------------------------------------------------------------
// -- GENERIC - RESET
// ------------------------------------------------------------------------

*,
*::before,
*::after {
    box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
}

strong,
b {
    font-weight: 700;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}


img {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

@media (prefers-reduced-motion: reduce) {
    * {
        transition-duration: .01ms !important;
        animation-duration: .01ms !important;
        animation-iteration-count: 1 !important;
        scroll-behavior: auto !important;
    }
}
