// ------------------------------------------------------------------------
// -- COMPONENTS - TEAM
// ------------------------------------------------------------------------

.team {
    overflow: hidden;
}

.team__inner {
    @media (min-width: $large) {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 -1rem;
    }
}

.team__item {
    font-size: 1.6rem;

    & + .team__item {
        margin-top: 3rem;
    }

    @media (min-width: $large) {
        margin: 2rem 1rem;
        flex: 0 1 calc(25% - 2rem);

        & + .team__item {
            margin-top: 2rem;
        }
    }
}

.team__name {
    color: $color-secondary;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: .5rem;
}

.team__job {
    font-style: italic;
    margin-bottom: 1.5rem;
}

.team__info {
    margin-bottom: .5rem;

    .icon {
        width: 2rem;
        margin-right: .5rem;
        vertical-align: middle;
        margin-top: -.3rem;
    }

    a {
        color: $color-secondary;

        &:focus,
        &:hover,
        &:active {
            color: $color-primary;
        }
    }
}