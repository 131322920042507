// ------------------------------------------------------------------------
// -- COMPONENTS - HEADER
// ------------------------------------------------------------------------

.header__inner {
    @media (min-width: $medium) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.header__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;

    @media (min-width: $medium) {
        background: none;
        align-self: flex-start;
        margin-bottom: -4rem;
        position: relative;
        z-index: 2;
    }
}

.header__navbtn {
    font-size: 2rem;
    line-height: 0;
    padding: .5rem 1rem;
    background: none;
    color: $color-secondary;
    border: .1rem solid $color-secondary;
    border-radius: .5rem;
    margin-right: 1rem;

    &:focus,
    &:hover,
    &:active {
        color: $color-primary;
        border-color: $color-primary;
    }

    @media (min-width: $medium) {
        display: none;
    }
}

.header__btn {
    display: flex;
    margin: 1rem 0;

    @media (min-width: $medium) {
        display: inline-flex;
    }
}



// -- LOGO
// ----------------------------------------------

.logo {
    display: block;
    padding: .5rem;
    background-color: $white;
    text-align: center;

    @media (min-width: $medium) {
        display: inline-block;
        padding: 1rem 1.5rem;
        border-radius: 0 0 2rem 2rem;
    }
}

.logo__img {
    display: inline-block;
    max-height: 6.5rem;

    @media (min-width: $medium) {
        max-height: 8rem;
    }

    @media (min-width: $large) {
        max-height: none;
    }
}