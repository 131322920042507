// ------------------------------------------------------------------------
// -- COMPONENTS - PARTNER
// ------------------------------------------------------------------------

.partner {
    overflow: hidden;
}

.partner__inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;

    @media (min-width: $large) {
        margin-left: -2rem;
        margin-right: -2rem;
    }
}

.partner__item {
    flex: 1 1 calc(50% - 2rem);
    margin: 1rem;
    border: .1rem solid $gray-200;
    text-align: center;
    transition: border-color .3s ease;

    @media (min-width: $large) {
        flex-basis: 0%;
        margin: 2rem;
    }

    &:focus,
    &:hover,
    &:active {
        border-color: $color-secondary;
    }

    img {
        display: inline-block;
    }
}