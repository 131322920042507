// ------------------------------------------------------------------------
// -- ELEMENTS - HEADINGS
// ------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: $margin-bottom-headings;
    font-weight: $font-weight-headings;
}

h1,
.h1-like {
    @include font-size(h1);
    @include line-height(title);
    @if variable_exists(font-family-headings) and $font-family-headings != $font-family-base {
        font-family: $font-family-headings;
    }
}

h2,
.h2-like {
    @include font-size(h2);
    @include line-height(title);
    @if variable_exists(font-family-headings) and $font-family-headings != $font-family-base {
        font-family: $font-family-headings;
    }
}

h3,
.h3-like {
    @include font-size(h3);
    @include line-height(title);
    @if variable_exists(font-family-headings) and $font-family-headings != $font-family-base {
        font-family: $font-family-headings;
    }
}

h4,
.h4-like {
    @include font-size(h4);
    @include line-height(title);
    @if variable_exists(font-family-headings) and $font-family-headings != $font-family-base {
        font-family: $font-family-headings;
    }
}

h5,
.h5-like {
    @include font-size(h5);
    @include line-height(title);
    @if variable_exists(font-family-headings) and $font-family-headings != $font-family-base {
        font-family: $font-family-headings;
    }
}

h6,
.h6-like {
    @include font-size(h6);
    @include line-height(title);
    @if variable_exists(font-family-headings) and $font-family-headings != $font-family-base {
        font-family: $font-family-headings;
    }
}
