// ------------------------------------------------------------------------
// -- COMPONENTS - ICONS
// ------------------------------------------------------------------------

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 0;
}

.icon__angleright {
    width: .5em;
}

.icon__bars {
    width: .875em;
}

.icon__calc {
    width: .875em;
}

.icon__mobile {
    width: .625em;
}

.icon__phonesound {
    width: .75em;
}

.icon__ship {
    width: 1.25em;
}
