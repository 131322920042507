// ------------------------------------------------------------------------
// -- OBJECTS - LAYOUT
// ------------------------------------------------------------------------

.container {
    max-width: $wrapsize;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    @media (min-width: $wrapsize + 30px) {
        padding-right: 0;
        padding-left: 0;
    }
}

.section {
    padding: 1.5rem 0;

    @media (min-width: $medium) {
        padding: 3rem 0;
    }

    &--highlight {
        background-color: $color-secondary-light;
        margin-bottom: 10rem;

        @media (min-width: $medium) {
            font-size: 2rem;
        }


    }
}

.section__box {
    background-color: $color-secondary-lighter;
    max-width: 90%;
    margin: 0 auto -8rem auto;
    padding: 3rem;

}