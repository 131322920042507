// ------------------------------------------------------------------------
// -- ELEMENTS - GLOBAL
// ------------------------------------------------------------------------

html {
    font-size: 62.5%;
    font-size: calc(1em * .625);
    scroll-behavior: smooth;
}

body {
@include font-size(base);
    @include line-height(base);
    color: $color-base;
    background-color: $bg-base;
    font-family: $font-family-base;
    font-weight: $font-weight-base;
}

a {
    color: $link-color;
    text-decoration: none;

    &:hover,
    &:active {
        color: $link-color-hover;
    }
}

p,
address,
ol,
ul,
dl,
blockquote,
figure,
pre,
dd {
    margin-top: 0;
    margin-bottom: $margin-bottom-paragraph;
}

li p,
li .p-like,
li ul,
li ol,
ol ol,
ul ul {
    margin-top: 0;
    margin-bottom: 0;
}

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video,
svg {
    max-width: 100%;
}

img {
    height: auto;
}

em,
.italic,
cite,
i,
var {
    font-style: italic;
}

dialog {
    display: block;
    border: 0;
}

.hr,
hr {
    display: block;
    height: 1px;
    clear: both;
    margin: 2em 0;
    padding: 0;
    color: $bg-base;
    border: 0;
    background-color: darken($bg-base, 5);

    &--small {
        margin: 1em 0;
    }

    &--white {
        background-color: $white;
    }
}

form,
fieldset {
    border: none;
}

fieldset {
    margin: 0;
    padding: 0;
}

label {
    display: block;
}

textarea {
    min-height: 7em;
    vertical-align: top;
    white-space: normal;
}

input[type="number"] {
    -moz-appearance: textfield;
}

label,
button {
    cursor: pointer;
}

button {
    &:focus {
        outline: none;
    }

    &::-moz-focus-inner {
        border-style: none;
    }
}

@media (max-width: ($small - 1)) {
    div,
    textarea,
    table,
    td,
    th,
    code,
    pre,
    samp {
        word-wrap: break-word;
        hyphens: auto;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$=".svg"] {
        width: 100%;
    }
}
