// ------------------------------------------------------------------------
// -- OBJECTS - LIST
// ------------------------------------------------------------------------

.list {
    li + li {
        margin-top: .5rem;
    }

    &--nopadding {
        padding-left: 1em;
    }

    &--nomargin {
        margin-bottom: 0;
    }

    &--unstyled {

        padding-left: 0;
        list-style: none;
    }

    @media (min-width: $medium) {
        &--inline {
            display: flex;
            margin: 0 -.75rem;
            flex-wrap: wrap;

            li {
                margin: .25rem .75rem;
                & + li {
                    margin-top: .25rem;
                }
            }
        }
    }
}

.list__icon {
    margin: -.3rem .5rem 0 1.5rem;
    color: $color-secondary;
    vertical-align: middle;
}