// ------------------------------------------------------------------------
// -- UTILITIES - HELPERS
// ------------------------------------------------------------------------

.bfc {
    overflow: hidden;
}

.is-hidden,
[hidden] {
    display: none;
}

.is-disabled,
[disabled],
.is-disabled ~ label,
[disabled] ~ label {
    cursor: not-allowed !important;
    opacity: .5;
    //color: $gray-600;
}


// -- COLOR HELPERS
// ----------------------------------------------

.color-primary {
    color: $color-primary;
}

.color-secondary {
    color: $color-secondary;
}

.color-success {
    color: $color-success;
}

.color-warning {
    color: $color-warning;
}

.color-error {
    color: $color-error;
}


// -- SIZE HELPERS
// ----------------------------------------------

.txt-small {
    font-size: 1.4rem;
}

.txt-big {
    @media (min-width: $large) {
        font-size: 1.8rem;
    }
}


// -- RESPONSIVE HELPERS
// ----------------------------------------------

@media (min-width: $mega-large) {
    .megalarge-hidden {
        display: none !important;
    }

    .megalarge-visible {
        display: block !important;
    }
}

@media (min-width: $extra-large) {
    .extralarge-hidden {
        display: none !important;
    }

    .extralarge-visible {
        display: block !important;
    }
}

@media (min-width: $large) {
    .large-hidden {
        display: none !important;
    }

    .large-visible {
        display: block !important;
    }
}

@media (min-width: $medium) {
    .medium-hidden {
        display: none !important;
    }

    .medium-visible {
        display: block !important;
    }
}

@media (min-width: $small) {
    .small-hidden {
        display: none !important;
    }

    .small-visible {
        display: block !important;
    }
}

@media (min-width: $tiny) {
    .tiny-hidden {
        display: none !important;
    }

    .tiny-visible {
        display: block !important;
    }
}
