// ------------------------------------------------------------------------
// -- COMPONENTS - BUTTONS
// ------------------------------------------------------------------------

.btn {
    display: inline-block;
    padding: .65rem 2rem;
    font-size: 1.6rem;
    font-family: $font-family-headings;
    vertical-align: middle;
    border: 0;
    text-align: center;
    user-select: none;
    white-space: nowrap;
    text-transform: uppercase;
    border-radius: 5rem;
    font-weight: bold;
    transition: all .2s ease;

    &--full {
        width: 100%;
    }

    &--primary {
        background: linear-gradient(to top, $color-primary 0%, $color-primary 50%, $white 50%, $white 100%);
        background-size: 100% 200%;
        background-position: 0 0;
        color: $color-primary;
        border: .2rem solid $color-primary;

        .btn__icon {
            fill: $color-primary;
        }

        &:focus,
        &:hover,
        &:active {
            color: $white;
            background-position: 0 100%;

            .btn__icon {
                fill: $white;
            }
        }
    }

    &--secondary {
        background: linear-gradient(to top, $color-secondary 0%, $color-secondary 50%, $white 50%, $white 100%);
        background-size: 100% 200%;
        background-position: 0 0;
        color: $color-secondary;
        border: .2rem solid $white;

        .btn__icon {
            fill: $color-secondary;
        }

        &:focus,
        &:hover,
        &:active {
            color: $white;
            background-position: 0 100%;
            border-color: $color-secondary;

            .btn__icon {
                fill: $white;
            }
        }
    }

    &--secondary-alt {
        color: $color-secondary;
        background: linear-gradient(to top, $color-primary 0%, $color-primary 50%, $white 50%, $white 100%);
        background-size: 100% 200%;
        background-position: 0 0;
        border: .2rem solid $white;

        .btn__icon {
            fill: $color-secondary;
        }

        &:focus,
        &:hover,
        &:active {
            color: $white;
            background-position: 0 100%;
            border-color: $color-primary;

            .btn__icon {
                fill: $white;
            }
        }
    }

}

.btn--hasiconleft,
.btn--hasiconright {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn__icon {
    position: relative;
    width: 1em;
    height: 1em;
    fill: $white;

    .btn--hasiconleft & {
        margin-right: 1rem;
    }

    .btn--hasiconright & {
        margin-left: 1rem;
    }
}