// ------------------------------------------------------------------------
// -- COMPONENTS - FORMS
// ------------------------------------------------------------------------

form,
.form,
fieldset {
    border: none;

    @media (min-width: $medium) {
        width: 70%;
        margin: 2rem auto;
    }
}

label,
.form__label {
    display: block;
    cursor: pointer;

    @media (min-width: $medium) {
        flex: 1 1 20%;
        text-align: right;
        margin-right: 3rem;
    }

    .form__checkwrap & {
        margin-right: 0;
        text-align: left;
    }
}

.form__required {
    color: $color-primary;
}

.form__alert {
    margin-bottom: 3rem;
    background-color: $gray-100;
}

.form__info {
    font-size: 1.2rem;
    margin-bottom: 3rem;

    @media (min-width: $medium) {
        margin-left: 20%;
    }
}

.form__checkwrap {
    display: flex;

    @media (min-width: $medium) {
        margin-left: 20%;
    }
}

.form__input,
[type="color"],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
    padding: .5rem 1rem;
    color: $color-base;
    border: 2px solid $gray-200;
    border-radius: .5rem;
    width: 100%;

    &:focus {
        border-color: $color-primary;
    }
}

.form__input {
    &--error {
        border-color: $color-error;
    }
}

textarea {
    min-height: 5em;
    vertical-align: top;
    white-space: normal;
}

[type="submit"] {
    width: 100%;

    @media (min-width: $medium) {
        margin-left: 20%;
        width: auto;
    }
}

.form__grp {
    margin-bottom: 1.5rem;

    @media (min-width: $medium) {
        display: flex;
    }
}



// -- CUSTOM SELECT / RADIO / CHECKBOX
// ----------------------------------------------

.form__select {
    padding-right: 2.5rem;
    background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1rem;
    appearance: none;
    &::-ms-expand {
        display: none;
    }
}

.form__checkbox {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    flex-shrink: 0;
    vertical-align: text-bottom;
    background-color: $white;
    box-shadow: inset 0 0 0 1px $gray-200;
    appearance: none;
    transition: background-color .3s ease;
    &::-ms-check {
        display: none;
    }
    &:focus {
        box-shadow: inset 0 0 0 1px $color-primary;
    }
    &:checked {
        background-color: $checkbox-color;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 60% 60%;
        box-shadow: inset 0 0 0 1px $checkbox-color;
    }
}

.form__radio {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
    vertical-align: text-bottom;
    border-radius: 50%;
    background-color: $white;
    background-size: 0% 0%;
    box-shadow: inset 0 0 0 1px $gray-200;
    appearance: none;
    &::-ms-check {
        display: none;
    }
    &:focus {
        box-shadow: inset 0 0 0 1px $color-primary;
    }
    &:checked {
        background-color: $checkbox-color;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkZpY2hpZXIgMjwvdGl0bGU+PGNpcmNsZSBjbGFzcz0iYSIgY3g9IjkiIGN5PSI5IiByPSI5Ii8+PC9zdmc+);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
        box-shadow: inset 0 0 0 1px $checkbox-color;
    }
}

.form__checkbox ~ label,
.form__radio ~ label,
.form__checkbox ~ .form__label,
.form__radio ~ .form__label {
    display: inline-block;
    margin-bottom: 0;
    margin-left: .5rem;
    margin-top: -.25rem;
    cursor: pointer;
    vertical-align: text-top;
}



// -- RADIO BTN
// ----------------------------------------------

.radiobtn {

    margin-top: 1.5rem;

    &__item {
        margin: .75rem 0;
        text-align: center;
        @media (min-width: $small) {
            display: inline-block;
            margin: .3rem;
            vertical-align: middle;
        }
    }

    &__input {
        clip: rect(1px 1px 1px 1px);
        position: absolute;
        &[checked] + .radiobtn__label {
            background-color: $color-primary;
            color: $white;
        }
        &:focus,
        &:hover,
        &:active {
            & + .radiobtn__label {
                box-shadow: 0 0 0 .3rem rgba($color-base, .85);
            }
        }
    }

    &__label {
        background-color: $gray-100;
        cursor: pointer;
        display: block;
        padding: .75rem 1.5rem;
    }
}


// -- RESPONSIVE
// ----------------------------------------------

@media (min-width: $small) {
    .form__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-right: -1rem;
        margin-left: -1rem;
        > .form__grp {
            max-width: 100%;
            flex-grow: 1;
            flex-basis: 0%;
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }
}
