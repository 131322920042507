// ------------------------------------------------------------------------
// -- COMPONENTS - BLOCKQUOTE
// ------------------------------------------------------------------------

.blockquote {
    display: flex;

    strong {
        color: $color-secondary;
    }
}

.blockquote__ttl {
    font-size: 1.8rem;

    @media (min-width: $medium) {
        font-size: 2.8rem;
    }
}

.blockquote__quote {
    font-size: 2rem;
    color: $color-secondary-light;
    line-height: 1;
    flex-shrink: 0;

    &--left {
        margin-right: 1rem;
    }

    &--right {
        margin-left: 1rem;
        align-self: flex-end;
    }

    @media (min-width: $medium) {
        font-size: 8rem;
        &--left {
            margin-right: 5rem;
        }

        &--right {
            margin-left: 5rem;
        }
    }
}

.blockquote__author {
    display: flex;
    align-items: center;
    margin-top: 3rem;
}

.blockquote__img {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1.5rem;
    flex-shrink: 0;

    @media (min-width: $small) {
        width: 10rem;
        height: 10rem;
    }

}

.blockquote__authorname {
    font-weight: bold;
    color: $color-secondary;
    display: block;
}